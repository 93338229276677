import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = ({ data }) => {
  const d = data.allContentfulServicesItem.nodes
  const title = data.contentfulServices.servicesTitle
  const desc = data.contentfulServices.seoDescription
  const articles = d

  return (
    <Layout>
      <div className="px-6">
        <SEO title={title} description={desc} />
        <h1 className="mb-4">{title}</h1>
      </div>
      {articles.map(article => (
        <div className="px-6 min-h-full" key={article.id}>
          <Link to={article.slug}>
            <div
              className="max-w-xl mx-auto my-6 p-6 bg-white md:flex rounded-lg shadow-light"
              role="button"
              title={article.name}
            >
              <img
                src={article.image.file.url}
                alt={article.name}
                className="md:h-24 md:w-24 md:min-h-24 md:min-w-24 h-16 w-16 min-h-16 min-w-16 rounded-full shadow-lg mx-auto md:self-end mb-4"
              />

              <div className="text-center md:text-left md:ml-6">
                <blockquote className="text-black text-sm">
                  <strong>{article.name}</strong>
                  <br />
                  {article.article.childMarkdownRemark.excerpt}
                </blockquote>
                <cite className="text-black">Click For More Details</cite>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulServices {
      seoDescription
      servicesTitle
    }
    allContentfulServicesItem(sort: { fields: createdAt, order: ASC }) {
      nodes {
        id
        name
        slug
        article {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 160)
          }
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`

export default ServicesPage
